<template>
<div>
  <a-spin :spinning="iconLoading" tip="下載中..." size="large">   
    <div id="itemlist" class="overflow-auto" style="height: 700px">      
      <div style="height: 960px">
        <div style="padding: 0.6rem 0 0 0" />
        <a-spin :spinning="spinnings" tip="資料處理中...">
          <b-card
            border-variant="secondary"
            header-border-variant="secondary"
            style="font-size: 14px"
            :header="' '"
            v-for="item in aclist"
            :key="item.id"
            class="text-left mb-2"
          >
            <b-card-text>
              <div class="position-relative" style="width: 100%">
                <b-avatar
                  size="46px"
                  style="margin-top: -2.7rem; margin-left: 5rem"
                  :src="item.priUrl"
                  class="position-absolute top-2 end-2"
                ></b-avatar>
              </div>
              <div style="padding: 0.02rem">
                <span style="margin-top: 0.02rem"
                  >姓名: {{ item.username }}</span
                ><br />
                <span
                  slot="extra"
                  v-text="'住址 : ' + geaddr(item.address)"
                ></span>
                <span
                  slot="extra"
                  class="font-weight-bold"
                  :style="{ color: item.states == 'Y' ? '#000099' : '#990033' }"
                  v-text="item.states == 'Y' ? '(已報到)' : '(未報到)'"
                ></span>
              </div>
              <a-button
                style="margin-top: 0.06rem; margin-bottom: -1rem"
                block
                slot="actions"
                type="primary"
                v-if="item.states == 'N' || item.states == ''"
                @click="enterOrder(item)"
              >
                手動報到
              </a-button>
            </b-card-text>
          </b-card>
          <a-button
            v-show="aclist.length>0 && myDataCheck"
            style="margin-bottom: 10px;padding:10px;"
            :loading="spinnings"
            @click="infiniteHandler()"
            type="danger"
            block
            shape="round"
            size="large"
            >
          <b-icon
              v-show="!spinnings"
              style="margin-right: 0.3rem"
              scale="1.1"
              icon="cloud-download"
              variant="light"
            />
            取得更多資料
        </a-button>  
        </a-spin>
        <div style="padding: 3.5rem 0 0 0" />
      </div>
    </div>
  </a-spin> 
</div> 
</template>

<script>
import * as moment from "moment/moment";
import { joinService } from "../../_services";
import { mapState } from "vuex";
//import router from '../../router'

const findall = (obj) => {
  return joinService.singlistall(obj);
};
const postData = (params) => {
  return joinService.signin(params);
};

export default {
  name: "Order",
  props: {
    fid: Number,
    aid: Number,
    objRS: Object,
  },
  data() {
    return {
      page: 1,
      checkgo: false,
      loading: false,
      aclist: [],
      items: "",
      iconLoading: false,
      spinnings: false,
      myDataCheck:true,
    };
  },
  computed: {
    ...mapState("account", ["user"]),
    getPeople() {
      let number = 0;
      for (var i in this.objRS.acts_actclass) {
        if (this.objRS.acts_actclass[i].aid == this.aid) {
          number = this.objRS.acts_actclass[i].acts_actstaff.length;
          break;
        }
      }
      return number;
    },
  },

  methods: {
    fdateformat(val) {
      return (
        moment(val.sdate).format("YYYY-MM-DD HH:mm") +
        "~" +
        moment(val.edate).format("MM-DD HH:mm")
      );
    },
    catImg(imgs) {
      return require(`../../assets/img/${imgs}`);
    },

    getxt(val) {
      return val.substring(1, 32);
    },

    geaddr(val) {
      return val.substring(0, 6);
    },

    godata(val) {
      this.fid = val;
      this.infiniteHandler();
    },

    async enterOrder(obj) {
      let val = {
        id: this.aid,
        sdate: obj.sdate,
        edate: obj.edate,
        fid: obj.fid,
        uid: obj.uid,
        code: "",
        sid: this.user.data.user.id,
      };
      this.spinnings = true;
      await this.toOrder(val)
        .then((val) => {
          return val;
        })
        .catch((val) => {
          return val;
        })
        .finally(() => {
          console.log("done");
        });
    },

    async toOrder(val) {
      return new Promise((resolve, reject) => {
        let now = moment();
        var dateTimeString = val.sdate;
        var parsedMoment = moment(dateTimeString, "YYYY-MM-DD HH:mm");
        var oneHourBefore = parsedMoment.subtract(1, 'hours');
        var myDates = oneHourBefore.format('YYYY-MM-DD HH:mm');   

        var dateTimeString2 = val.edate; //晚一小時
        var parsedMoment2 = moment(dateTimeString2, "YYYY-MM-DD HH:mm");
        var oneHourAfter = parsedMoment2.add(1, 'hours');
        var myDates2 = oneHourAfter.format('YYYY-MM-DD HH:mm');        

        let tmp = { msg: "done", code: 300, data: {} };
        if (
          myDates <=
          now.format("YYYY-MM-DD HH:mm") &&
          myDates2 >
            now.format("YYYY-MM-DD HH:mm")
        ) {
          val.sdate = moment(val.sdate).format("YYYY-MM-DD HH:mm");
          val.edate = moment(val.edate).format("YYYY-MM-DD HH:mm");
          postData({
            ...val,
          }).then(({ data }) => {
            if (data.data.code == 200) {
              this.spinnings = false;
              for (var item in this.aclist) {
                if (this.aclist[item].uid == val.uid) {
                  this.aclist[item].states = "Y";
                  break;
                }
              }
              resolve(data);
            } else {
              this.spinnings = false;
              reject(tmp);
            }
          });
        } else {
          setTimeout(() => {
            this.spinnings = false;
          }, 500);          
          reject(tmp);
        }
      });
    },

    async infiniteHandler() {
      this.iconLoading = true;
      let params = {
        page: this.page,
        fid: this.fid,
        aid: this.aid,
      };
      let rs=await this.getOrder(params)
      this.iconLoading = false;
      if(!rs){
        this.myDataCheck=false;
      }
    },

     getOrder(params) {
      return new Promise((resolve) => {
        this.page++;
        findall({
          ...params,
        }).then(({ data }) => {
          let obj = {
            hits: data.data,
          };
          if (obj.hits == null) {
            obj.hits = [];
          }
          if (obj.hits.length) {
            this.aclist.push(...obj.hits);
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },
  },
  created() {
    this.infiniteHandler();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#itemlist {
  margin: 0px auto;
  padding: 0px;
  max-width: 1024px;
}

img {
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
  display: block;
  clip-path: polygon(-4px -4px, 100.4% -3px, 99.2% 102.57%, -0.8% 102.97%);
}

.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  width: min(98%, 500px);
  margin: 0 auto;
  box-shadow: 0 20px 34px hsla(20deg, 1%, 52%, 0.29);
  overflow: hidden;
}

.cards-details {
  position: relative;
  padding: 0 0.05rem 0 0.5rem;
}

.fontstyle {
  font-size: 0.8rem;
  color: #ffffff;
}

.spin-content {
  padding: 60px;
  box-shadow: 0 20px 34px hsla(20deg, 1%, 52%, 0.29);
}
</style>





