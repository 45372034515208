<template>
  <div>
    <div class="lineHeader">
                <div class="input-group" id="searchbar">
                    <span class="input-group-text">選擇主辦單位</span>
                    <select
                      v-model="selected"
                      @change="ontype()"
                      class="form-select"
                      aria-label="First name"
                    >
                    <option v-for="list in data" :value="list.id" :key="list.id">{{list.name}}</option>
                    </select>
                    <span style="padding-left: 1rem;"/><b-button pill @click="ReLogin()" variant="primary">重登</b-button>
                </div>       

    </div>
      <b-modal ref="my-lines" size="sm" centered @hide="onHide" id="LineLg" title="Line 登入" hide-header hide-footer>
        <div class="text-center">
        <Linelogin />
        </div>
      </b-modal>     
  </div>
</template>

<script>
import Linelogin from "../../auth/Linelogin.vue";
import { mapState, mapActions,mapMutations } from "vuex";
export default {
  name: "Linemenubar",
  components: {
    Linelogin,
  },
  props: {
    data: Array,
  },
  data() {
    return {    
      show: null,
      selected:null,
    };
  },
  methods: {
    ...mapActions("account", ["checktoken", "reLogin"]),
    ...mapMutations("account", ["setpage"]),    

    showline() {
      this.$refs["my-lines"].show();      
    },

    closeline() {
      this.$refs["my-lines"].hide();      
    },    
 
    onHide(evt) {
          if(evt.trigger === "backdrop"){
              evt.preventDefault();
          }
      },
    ontype(){
      let temp =  this.data.filter(rs => rs.id ==this.selected)
      this.$emit("ontype",temp)
    },
    ReLogin() {   
      let obj = { id: "Lineorg" };
      this.setpage(obj) 
      this.reLogin(); 
      this.$emit("reget")
    },    
   
  },
  

  computed: {
    ...mapState("account", ["status", "user"]),
  },
};
</script>
<style scoped>
.lineHeader {
   position: fixed;
   left: 0;
   top: 0;
   z-index: 7;
   opacity:1;
   width: 100%;
   height: 3.3rem;
   padding: 0.5rem;
   background-color: #3b1b75;
   color: #f0f8f8;
   text-align: center;
   
}
#searchbar{
  width:80%;
  margin: 0 auto;
}
</style>
<style>
#LineLg > .modal-dialog > .modal-content > .modal-body {
  border-color: transparent;
  background-color: transparent !important;
  color: white;
} 
#LineLg > .modal-dialog > .modal-content {
  border-color: transparent;  
  background-color: transparent !important;
} 
</style>