<template>
  <div>
    <div style="padding: 1.2rem 0 0.5rem 0;border-bottom: 0.13rem dotted #ebebe8;">
    活動狀態 :  <a-switch checked-children="進行中" un-checked-children="結束" @change="onChange" default-checked />
    </div>
    <a-table
      :columns="columns"
      :row-key="record => record.id"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      :locale="locale"             
      @change="handleTableChange"
    >
      <template slot="sdate" slot-scope="record">
          <b-button variant="link" size="sm" @click="showData(record.id)">
            <b-icon icon="calendar3" aria-hidden="true"></b-icon>
          </b-button>
      </template>
      <template slot="actitle" slot-scope="record">
        {{record.actitle}}
      </template>
      <template slot="states" slot-scope="record">
        <span v-text="ctypes(record.states)"></span>
      </template>
    </a-table>

    <b-modal
      ref="my-acts"
      size="lg"
      centered
      @hide="onHide"
      id="modalActs"
      title
      hide-header
      hide-footer
    >
      <div class="text-center" id="cbodyer">
        <div>
          <a-spin :spinning="spinnings" tip="資料載中...">
          <div class="orheader">
            <span v-text="'《 關閉視窗 》'" @click="toclose()" style="cursor: pointer;" />
          </div>          
          <ActDate @done="done" ref="conD"/>
          </a-spin>
        </div>
      </div>
    </b-modal>    

  </div>
</template>   
<script>
import * as moment from "moment/moment";
import { actService, macService } from "@/_services";
import ActDate from "./ActDate.vue"
import 'moment/locale/zh-tw';

const queryData = params => {
  return actService.Findactpagebystate(params);
};

const delData = params => {
  return macService.macDel(params);
}

const columns = [
  {
    title: "",
    dataIndex: "",
    width: "10%",
    ellipsis: true,
    scopedSlots: { customRender: "sdate" }
  },
  {
    title: "活動名稱",
    dataIndex: "",
    width: "45%",
    ellipsis: true,
    scopedSlots: { customRender: "actitle" }
  },
  {
    title: "狀態",
    dataIndex: "",
    ellipsis: true,
    scopedSlots: { customRender: "states" },
    width: "20%"
  },
];

export default {
  name: "AcTables",
  components: {
    ActDate,
  },  
  props: {
    norg:{
      type: Number
    },
    items: {
      type: Array
    }
  },
  data() {
    return {
      pagination: { defaultPageSize: 4 },
      columns,
      loading: false,
      spinnings:false,
      ids:'Y',
      data: [],
      rocid:'',
      locale:{
        filterConfirm:'確定',
        filterReset: '重置',
        emptyText: '暫時無任何數據'        
      },
    };
  },
  computed: {
  },
  mounted() {
    this.reload();
  },
  watch: {
    ids() {
      this.reload();
    },
  },
  methods: {
    onChange(checked) {
       if(checked){
        this.ids = 'Y'
       }else{
        this.ids = 'F'
       }
    },

    handleTableChange(pagination, filters, sorter) {
      //console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      //console.log(this.pagination);
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        uid: this.norg,
        state: this.ids,
        ...filters
      });
    },

    fetch(params = {}) {
      this.loading = true;
      queryData({
        ...params
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.info.totail;
        //pagination.total = 200;
        this.loading = false;
        this.data = data.results;
        this.pagination = pagination;
      });
    },

    async del(id,fid){
      this.loading = true;
      let rsults = await this.updata({id,fid})  
      setTimeout(() => {
        if(rsults.data==null || rsults.data==''){
          this.reload();
        }  
      }, 1600);      
    },    

    async updata(params={}){
     return await delData({
        ...params,
      }).then(({ data }) => {  
        let obj = data;      
        return obj
      });
    },    

    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD");
    },
    ctypes(val) {
      //return狀態 N草稿, I審核中, R發佈中, F結束, D下架
      let tmp = "";
      val == "N" ? (tmp = "草稿") : "";
      val == "I" ? (tmp = "審核中") : "";
      val == "Y" ? (tmp = "發佈中") : "";
      val == "F" ? (tmp = "結束") : "";
      val == "D" ? (tmp = "下架") : "";
      return tmp;
    },

    reload() {
      this.fetch({
        results: 4,
        page: 1,
        pageSize: 4,
        uid: this.norg,
        state: this.ids
      });
    },

    reload2(va1) {
      this.fetch({
        results: 4,
        page: 1,
        pageSize: 4,
        uid: va1,
        state: this.ids
      });
    },    

    showData(id){
      this.roCid=id
      this.$refs["my-acts"].show();
      this.spinnings=true          
          setTimeout(() => {
            this.$refs["conD"].loadData(id);     
        }, 500);          

    },
    done(va1){
      this.spinnings=va1
    },
    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    }, 
    toclose() {
      this.$refs["my-acts"].hide();
    },       

  }
};
</script>

<style scoped>
.myTable thead {
  background-color: #000000;
}
</style>
<style>
.hidden_cssd {
  border-top: 1px solid #f1f1f0;
  border-bottom: 3px solid #ebebe8;
  border-style: dotted;
}
</style>