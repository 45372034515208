<template>
  <div class="overflow-hidden">
    <div class="d-flex flex-column min-vh-100">
      <b-alert
        v-model="visible"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000;"
        variant="danger"
        dismissible
      >
      <span v-html="answer" />
      </b-alert>
      <ul>
        <li>
          <div class="mt-3" id="itemlist">
            <div style="padding:0.8rem 0 0 0;" />            
            <a-calendar
              @select="onSelect"
              @panelChange="getChange"
              :defaultValue="currentTime"
              :locale="locale"
              :fullscreen="true"              
              :style="{ height:'49.8rem' ,border: '1px solid #d9d9d9', borderRadius: '4px' }"
              class="overflow-hidden">
              <div slot="dateCellRender" slot-scope="value" class="events" style="overflow-x: hidden;overflow-y:hidden;">
                <div v-for="item  in getListData(value)" :key="item.id"  >
                  <div class="notes" :style="{'background-image': item.type==='Y'?'linear-gradient(to bottom, #c5ddf8, #3386e7)':'linear-gradient(to bottom, #dbdedb, #aeb1ae)','cursor':'pointer'}">   
                    <b-icon :icon=" item.type==='Y'? 'flag-fill':'file-earmark-excel-fill'" style="margin-top:1.8rem;color:aliceblue;" font-scale="2" ></b-icon>                           
                  </div>
                </div>
              </div> 
              <template slot="monthCellRender" slot-scope="value">
                <div v-if="getMonthData(value)" class="notes-month">
                  <section>{{ getMonthData(value) }}</section>
                  <span>Backlog number</span>
                </div>
              </template>
            </a-calendar>
          </div>
        </li>
      </ul>
    <div style="padding:3rem 0 0 0;" />
    </div>
    <b-modal
      ref="my-act"
      size="sm"
      centered
      @hide="onHide"
      id="modalQrCode"
      :title="mytitle"
      hide-footer
    >
    <a-spin :spinning="loadings" tip="資料載中...">
      <div class="text-center">
        <div v-if="list">
          <div
            v-for="(item, $index) in lists"    
            :key="$index"  
            style="margin:5px 5px 15px 5px;border-top-right-radius:25px; 
            box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;"      
          >
            <b-card
              :img-src="item.url!==''? item.url:mainImg('event_1080x540.png')"
              img-alt="Image"
              img-top
              class="mb-2">
              <b-card-text>
                  <div class="text-left fontstyle3">
                    <div v-text="rename(item.actitle)" class="fontstyle3" style="padding-left: 1rem;color:black;" />
                  <div v-text="'時間 : '+ converts(item.sdate,item.edate)" style="padding-left: 0.5rem;  font-size: 0.8rem;font-weight:bold;color: #383030;" /> 
                  <b-button-group >                  
                    <b-button class="fontstyle4"  variant="success" @click="toConten(item.fid)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-card-list"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"
                      />
                      <path
                        d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"
                      />
                    </svg>
                  </b-button>
                  <b-button
                    class="fontstyle4"
                    variant="secondary"
                    :disabled="!(fdateformat2(item.sdate)<fdateformat(currentTime) && fdateformat3(item.edate)>fdateformat(currentTime)) || item.cfstate=='F'?true:false"
                    @click="toQrcode(item)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      class="bi bi-qr-code"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 2h2v2H2V2Z" />
                      <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z" />
                      <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z" />
                      <path
                        d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z"
                      />
                      <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z" />
                    </svg>
                  </b-button>
                  <b-button
                      class="fontstyle4"
                      variant="primary"
                      :disabled="!(fdateformat2(item.sdate)<fdateformat(currentTime) && fdateformat3(item.edate)>fdateformat(currentTime)) || item.cfstate=='F'?true:false"
                      @click="toOrder(item)"
                    >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      class="bi bi-person-check"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                      />
                    </svg>
                  </b-button>
                  <b-button
                      class="fontstyle4" 
                      variant="warning"
                      :disabled="!(fdateformat2(item.sdate)<fdateformat(currentTime) && fdateformat3(item.edate)>fdateformat(currentTime)) || item.cfstate=='F'?true:false"
                      @click="toScan(item)"
                    >                    
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      class="bi bi-camera"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"
                      />
                      <path
                        d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"
                      />
                    </svg>
                  </b-button>                                    
                  </b-button-group>  
              </div>  
              </b-card-text>
            </b-card>
          </div>   
        </div>
      </div>
    </a-spin>
    </b-modal>
    <b-modal
      ref="my-qr"
      size="sm"
      centered
      @hide="onHide"
      id="modalQr"
      title
      hide-header
      hide-footer
    >
      <div class="text-center" id="cbodyer">
        <div v-if="!list" @click="toclose()" style="cursor: pointer;">
          <div class="qrheader">
            <a-button
                type="link"
                value="large"
                icon="printer"
                style="margin-left: 1rem;font-size: 16px;color: white;"
                :loading="iconLoading2"
                @click="exportToPDF"
              >(列印)</a-button>                
            <span v-text="'關閉✘'" style="margin-left: 1rem;font-size: 16px;color: white;"/>
                     
          </div>
          <div class="qrbody">
            <vue-qr
              ref="qrCode"
              :text="textValue"
              :logoSrc="logoPath"
              :logoScale="40"
              :margin="10"
            />
            <div class="classempy" id="word1head" v-show="iconLoading2">
              <div id="word123" class="text-center">
                <span style="font-size: 28px;margin-bottom: 2rem;" v-html="displayname"></span>
                <vue-qr
                  ref="qrCode"
                  :text="textValue"
                  :logoSrc="logoPath"
                  :logoScale="40"
                  :size="650"
                  :margin="10"
                />
              </div>
            </div>            
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="my-Ord"
      size="sm"
      centered
      @hide="onHide"
      id="modalQr"
      title
      hide-header
      hide-footer
    >
      <div class="text-center" id="obodyer">
        <div v-if="!list">
          <div class="orheader">
            <span v-text="'《 關閉點名 》'" @click="tocloseOrd()" style="cursor: pointer;" />
          </div>
          <div class="orbody">
            <Order id="style-3" :fid="fid" :aid="aid" :objRS="objRS" ref="com4" />
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal ref="my-scan" size="sm" centered @hide="onHide" id="modalQr" title="QR Code" hide-header  hide-footer>
        <div class="text-center" id="cheader">              
              <b-button @click="closecam()" style="color:#000204;" variant="link">
                <b-icon icon="camera" font-scale="1.5"   variant="dark"></b-icon>
                關閉
              </b-button>              
        </div>
        <div class="text-center" id="cbodyer">
               <div v-if="tipMess!=''" style="padding:0.5rem;color:black;font-weight: bold;font-size: 22px;"  v-text="tipMess"></div>
               <a-spin :spinning="mySinning" tip="資料掃描中...">
                  <qrcode-stream :camera="camera" :track="paintOutline" @init="onInit" @decode="onDecode"></qrcode-stream>
               </a-spin>
        </div>
      </b-modal>     
  </div>
</template>

<script>
import { mapState,mapMutations } from "vuex";
import { actService,joinService,userService } from "@/_services";
import * as moment from "moment/moment";
import VueQr from "vue-qr";
import router from "@/router";
import { QrcodeStream} from 'vue-qrcode-reader'
import logoImg from "@/assets/lineicon.svg";
import Order from "./Order.vue";
import 'moment/locale/zh-tw';
import html2pdf from "html2pdf.js";


const queryData2 = params => {
  return actService.findmyactbyDate2(params);
};

const queryus = params => {
  return userService.findusid(params);
};


const queracty = params => {
  return actService.findmyactaid(params); 
}

const postData = params => {
  return joinService.signin(params)
};

export default {
  name: "ViewScans",
  components: {
    VueQr,
    Order,
    QrcodeStream,
  },
  data() {
    var my_date = new Date();
    var FDate = new Date(my_date.getFullYear(), my_date.getMonth(), 1);
    var LDate = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0);
    return {
      showTop: false,
      loadings: false,
      message: "",     
      currentTime: moment(),
      arryitems: [],
      information: [],
      lists: [],
      mytitle:'',
      logoPath: logoImg,
      textValue: "",
      count:0,
      list: true,
      visible: false,
      loading: false,              
      camera: 'auto',  
      tipMess:'',
      answer: "",
      putObj:{},
      fid: "",
      aid: "",
      objRS:null,
      firstDate:FDate,
      lastDate:LDate,
      getOid:null,
      displayname: "",      
      iconLoading2: false, 
      allowDecode: true,
      mySinning:false,    
      locale:{
        lang:{
          month:'月',
          year:'年',
        }
      },       
    };
  },
  mounted(){
      this.suppliergini()
  }, 
  computed: {
    ...mapState("account", ["user"]),
    ...mapState("sing", ["singsupplier"]),    
  }, 
  methods: {
    ...mapMutations('sing',['suppliergini', 'supplierremov', 'supplieradd', 'suppliermove']),    
    catImg(imgs) {
      return require(`../../assets/${imgs}`);
    },
    
    mainImg(imgs) {
      return require(`../../assets/${imgs}`);
    },     
    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD HH:mm");
    },
    fdateformat2(val) {  //早一小時
      var dateTimeString = val;
      var parsedMoment = moment(dateTimeString, "YYYY-MM-DD HH:mm");
      var oneHourBefore = parsedMoment.subtract(1, 'hours');
      var myDates = oneHourBefore.format('YYYY-MM-DD HH:mm');      
      return myDates;
    },

    fdateformat3(val) { //晚一小時
      var dateTimeString2 = val; 
      var parsedMoment2 = moment(dateTimeString2, "YYYY-MM-DD HH:mm");
      var oneHourAfter = parsedMoment2.add(1, 'hours');
      var myDates2 = oneHourAfter.format('YYYY-MM-DD HH:mm');  
      return myDates2;
    },    

    getdate(val) {
      return moment(val).format("YYYY-MM-DD");
    },
    toNext() {
      router.replace({ name: "Myline" });
      //router.go(-1)
    },

    converts(v1,v2){
      return moment(v1).format("YYYY-MM-DD HH:mm") +'~'+ moment(v2).format("HH:mm");
    },

    rename(value){
      return value.length >= 15 ? value.substring(0,10)+'...':value
    },
    getpeople(id,rs){
      for(var i in rs){
        if(rs[i].aid== id){ 
          let d1=0,d2=0,d3=0,d4=0          
          for(var j in rs[i].acts_actstaff){
            rs[i].acts_actstaff[j].roles=='volunteer'? d1=d1+1:''
            rs[i].acts_actstaff[j].roles=='coach'? d2=d2+1:''
            rs[i].acts_actstaff[j].roles=='actor'? d3=d3+1:''
            rs[i].acts_actstaff[j].roles=='actor' && rs[i].acts_actstaff[j].sid!==''? d4=d4+1:''
          }  
          return '講師 :'+ d2 +'  志工 : '+ d1 +'<br/>  參加者 : ' +  d3  + ' 報到 : ' + d4;                
        }
      }

    },

    toQrcode(val) {
      this.list = false;
      let tmp =
        "actjoin:" +val.fid +
        "," +
        val.sdate +
        "," +
        val.edate +
        "," +
        val.id +
        "," +
        this.user.data.user.id +
        "," +        
        val.acts_actclass[0].value
        ;
      this.textValue = tmp;
      this.displayname = '活動名稱 : ' + val.actitle +'<br/> 活動時間 : '+ val.sdate +' ~ '+ val.edate
      this.$refs["my-qr"].show();
    },

    toOrder(val) {
      this.list = false;
      this.fid = val.fid;
      this.aid = val.id;
      this.objRS = val
      //this.$refs["com4"].godata(val)
      this.$refs["my-Ord"].show();
    },
    toScan(val) {
      this.putObj ={
            id: val.id,
            sdate: val.sdate,
            edate: val.edate,
            fid: val.fid,
            uid: '',
            sid: this.user.data.user.id, 
            limits: parseInt(val.acts_actclass[0].value)       
          }
      this.ScanReload()
      this.list = false;
      this.fid = val.fid;
      this.aid = val.id;
      this.tipMess='';
      this.$refs["my-scan"].show();
    }, 

    async onDecode(decodedString) {
        if (!this.allowDecode) return;
        this.allowDecode = false;      
        let rs = this.putObj   
        this.mySinning=true 
        if(decodedString.substring(0, 8)==='actjoin:'){
          let strAry = decodedString.split(':');
          rs.uid =parseInt(strAry[1])
          let ch1 = this.singsupplier.find(element => element.id ==rs.id && element.uid ==rs.uid);
          if(this.singsupplier.length==0 || ch1==undefined){          
          try {
              let tman = await this.getUs(rs);
              if(tman!==null){
                await this.toScans(rs);
                this.supplieradd({id:rs.id,uid:rs.uid})
              } else {
                this.tipMess = "報到時間錯誤";
              }
            } catch (error) {
              this.tipMess = "報到失敗";
            }
          }else{   
            this.tipMess="重複報到"   
          }        
      }else{
        this.tipMess="QRCode格式錯誤"
      } 
      this.mySinning=false   
      await this.timeout(1200)
      this.allowDecode = true; 
    },

    toScans(val) {
        return new Promise((resolve, reject) => {
          let now = moment();
          var dateTimeString = val.sdate;
          var parsedMoment = moment(dateTimeString, "YYYY-MM-DD HH:mm");
          var oneHourBefore = parsedMoment.subtract(1, 'hours');
          var myDates = oneHourBefore.format('YYYY-MM-DD HH:mm');

          var dateTimeString2 = val.edate; // 晚一小時
          var parsedMoment2 = moment(dateTimeString2, "YYYY-MM-DD HH:mm");
          var oneHourAfter = parsedMoment2.add(1, 'hours');
          var myDates2 = oneHourAfter.format('YYYY-MM-DD HH:mm');

          let tmp = { msg: "done", code: 300, data: {} };
          if (myDates <= now.format('YYYY-MM-DD HH:mm') && myDates2 > now.format('YYYY-MM-DD HH:mm')) {
            val.sdate = moment(val.sdate).format('YYYY-MM-DD HH:mm');
            val.edate = moment(val.edate).format('YYYY-MM-DD HH:mm');
            postData({
              ...val,
            }).then(({ data }) => {
              tmp.code = parseInt(data.data.code);
              if (tmp.code == 200 || tmp.code == 208) {
                this.tipMess = "報到成功";
                tmp.code == 200 ? this.tipMess = "報到成功" : '';
                tmp.code == 208 ? this.tipMess = "報到失敗(活動已額滿)" : '';
              } else {
                if (tmp.code == 300) {
                  this.tipMess = "重複報到";
                }
              }
              resolve(tmp); // 成功时 resolve
            }).catch(error => {
              reject(error); // 捕获 postData 的错误
            });
          } else {
            reject(tmp); // 时间不符合条件时 reject
          }
        });
    },
    
    
    unpause () {
      this.camera = 'auto'
    },  
    
    pause () {
      this.camera = 'off'
    },  
    
    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },    

    closecam(){
      this.$refs["my-scan"].hide();
      this.$refs["my-act"].hide();
    }, 
    async ScanReload () {
      this.pause()
      await this.$nextTick();
      this.unpause();
    },  
    
    paintOutline (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints

        ctx.strokeStyle = "red";
        ctx.lineWidth = 4;

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },  
    
    async onInit (promise) {
      this.mySinning=true
      try {
        await promise
      } catch (error) {
        console.error(error)
      } finally {
        this.mySinning=false      
      }
    },      

    tocloseOrd() {
      this.list = true;
      this.$refs["my-Ord"].hide();
    },

    toclose() {
      this.list = true;
      this.$refs["my-qr"].hide();
    },

    getListData(value) {
      let listData = [];
      if (this.arryitems !== null) {
        this.arryitems.forEach(item => {
          if (
            moment(item.sdate).format("YYYY-MM-DD") ==
            value.format("YYYY-MM-DD")
          ) {
            listData.push({
              type:
                moment(item.sdate).format("YYYY-MM-DD") >=
                this.currentTime.format("YYYY-MM-DD")
                  ? "Y"
                  : "N",
              id: item.id,
              fid: item.fid,
              orgid: item.orgid,
              url: item.url,
              actitle: item.actitle,
              sdate: item.sdate,
              edate: item.edate,
              state: item.cfstate
            });
          }
        });
        return listData || [];
      }
      return listData || [];
    },

    toConten(id) {
      router.push({ name: "Myapply", params: { id } });
    },

    async onSelect(e) {
      this.information = this.arryitems.filter(
        rs =>
          moment(rs.sdate).format("YYYY-MM-DD") ==
          moment(e).format("YYYY-MM-DD")
      );
      this.mytitle = "我的活動資訊 : "+ moment(e).format("YYYY-MM-DD")
      if (this.information.length > 0) {
        this.count=0
        this.loadings =true;
        this.list = true;
        this.$refs["my-act"].show();
        this.lists=[]
        let j=0
        for(var i in this.information){
          this.lists.push(this.information[i])
          j++
        }
        if(this.lists.length===j){
          this.loadings =false;
        }
        
      } else {
        this.visible = true;
        this.answer = "無資料!";
        setTimeout(() => {
          this.answer = "";
          this.visible = false;
        }, 1400);        
      }
    },

    getChange(e){
      var my_date = new Date(moment(e).format("YYYY-MM-DD"));
      this.firstDate = new Date(my_date.getFullYear(), my_date.getMonth(), 1);
      this.lastDate = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0);
      if(this.getOid!==null){
        this.getDatas()
      }      
    },

    getMonthData(value) {
      if (value.month() === 8) {
        return 1394;
      }
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },

    reload(val) { 
      this.getOid = val[0].id
      this.getDatas()
    },

    reData(va1,va2){
      this.getOid = va1[0].id
      this.firstDate=va2.firstDate,
      this.lastDate=va2.lastDate,
      this.getDatas()
    },

    getDatas(){
      this.fetch({ uid:this.user.data.user.id,id: this.getOid,firstDate:moment(this.firstDate).format("YYYY-MM"),lastDate:moment(this.lastDate).format("YYYY-MM")});
    },

    fetch(params = {}) {
      this.$emit('loads',true)
      this.loading = true;
      queryData2({
        //results: 4,
        ...params //...解壓縮出來
      }).then(({ data }) => {
        this.arryitems = data;
        this.$emit('loads',false)
      });
    },


    getCount(params = {}) {
      return new Promise((resolve) => {
          queracty({
          ...params //...解壓縮出來
        }).then(({ data }) => (resolve(data)));
      })      
    }, 

    getUs(val){
      return new Promise((resolve,reject)=>{  
        let now = moment()          
        var dateTimeString = val.sdate;
        var parsedMoment = moment(dateTimeString, "YYYY-MM-DD HH:mm");
        var oneHourBefore = parsedMoment.subtract(1, 'hours');
        var myDates = oneHourBefore.format('YYYY-MM-DD HH:mm');   
        
        var dateTimeString2 = val.edate; //晚一小時
        var parsedMoment2 = moment(dateTimeString2, "YYYY-MM-DD HH:mm");
        var oneHourAfter = parsedMoment2.add(1, 'hours');
        var myDates2 = oneHourAfter.format('YYYY-MM-DD HH:mm');        

        let tmp = null
        if(myDates <= now.format('YYYY-MM-DD HH:mm') && myDates2 > now.format('YYYY-MM-DD HH:mm')){  
          val.sdate = moment(val.sdate).format('YYYY-MM-DD HH:mm')
          val.edate = moment(val.edate).format('YYYY-MM-DD HH:mm')
          queryus({
            ...val,
          }).then(( data ) => { 
              if(data!==null){ 
                  tmp = data     
                  resolve(tmp)                                     
              }else{
                reject(tmp) 
              } 
          });
        }else{
          reject(tmp)           
        }
        
      })
    }, 

    exportToPDF() {
      // pdf匯出設定
      this.iconLoading2 = true;
      const opt = {
        margin: 0.3,
        filename: "QRCode.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: { dpi: 192, letterRendering: true, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" }
      };
      this.domList = [];
      // 抓取各個圖片的DOM元素，並把它裝入doc這個陣列裡  
      let newDiv = document.createElement("div");  
      //let j = this.items.act_detrimal.length  
      let head1 = document.getElementById("word1head");
      const clone1 = head1.cloneNode(true);
      newDiv.appendChild(clone1);
      let element1 = document.getElementById("word123");
      this.domList.push(element1);
      // 開始進行列印
      let doc = html2pdf()
        .from(this.domList[0])
        .set(opt)
        .toPdf();
      for (let j = 1; j < this.domList.length-1; j++) {
        doc = doc
          .get("pdf")
          .then(pdf => pdf.addPage())
          .from(this.domList[j])
          .toContainer()
          .toCanvas()
          .toPdf();
      }
      // 列印完成輸出done
      doc
        .save()
        .then(() => (this.iconLoading2 = false));
      //
    },     


  }
};
</script>
<style scoped>
#itemlist {
  margin: 0px auto;
  padding: 0px;
  max-width: 1024px;
  text-align: left;
}

img {
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
  display: block;
  clip-path: polygon(-4px -4px, 100.4% -3px, 99.2% 102.57%, -0.8% 102.97%);
}

.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  width: min(98%, 500px);
  margin: 0 auto;
  box-shadow: 0 20px 34px hsla(20deg, 1%, 52%, 0.29);
  overflow: hidden;
}

.cards-details {
  text-align: left;
  position: relative;
  padding: 0 0.05rem 0 0.5rem;
}

.applyfooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  color: #f0f8f8;
  text-align: center;
  z-index: 7;
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.lineHeader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 7;
  opacity: 1;
  width: 100%;
  height: 2.3rem;
  padding: 0.2rem;
  background-color: #3b1b75;
  color: #f0f8f8;
  text-align: center;
}

.fontstyle {
  font-size: 0.8rem;
  color: #faf8f8;
}

.fontstyle2 {
  font-size: 0.9rem;
  font-weight:bold;
  color: #383030;
}

.notes{
  position: absolute;
  border-radius: 15px;
  top: 25%;
  left: 0;
  width: 80%;
  height: 70%;
  text-align: center; /* 文本水平居中 */
  vertical-align: middle; /* 文本垂直居中 */  
  cursor:pointer;
  }

#cheader{
    margin:0px auto;
    padding:0px;
    background-color:#ffffff;
    width:50%;
    border-top-left-radius:10.5rem;
    border-top-right-radius:10.5rem;
  }
  #cbodyer{
    padding:10px;
    background-color:#ffffff;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    border-top-left-radius:10px;
    border-top-right-radius:10px;
  }

.qrbody {
  padding: 0.4rem;
  background-color: #76d27a;
  color: white;
  border-radius: 0 0 0.5rem 0.5rem;
  border-bottom-style: dotted;
  border-color: #3e3e3e;
  border-width: 0.35rem;
  border-left-style: dotted;
  border-right-style: dotted;
}

.qrheader {
  padding: 0.6rem;
  background-color: #6dc871;
  color: white;
  border-radius: 1.3rem 1.3rem 0 0;
  border-color: #3e3e3e;
  border-bottom-style: dotted;
  border-width: 0.2rem;
}

.orbody {
  padding: 0.6rem;
  background-color: #cfd8cf;
  color: #303a31;
  border-radius: 0 0 0.5rem 0.5rem;
}

.orheader {
  padding: 0.6rem;
  background-color: #519955;
  color: white;
  border-radius: 0.3rem 0.3rem 0 0;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(152, 167, 189, 0.3);
  background-color: #f5f5f5;
}
#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
#style-3::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
}
#style-3 {
  text-align: left;
}

.fontstyle3 {
font-size: 0.93rem;
font-weight:bold;
color: #2c055e;
padding: 0.4rem;
background:linear-gradient(45deg,#7fdac1 0%,#5decde 60%,#ddf0e6 60%,#93cea2 100%);
opacity:0.85;
}
.card-body{
  padding:0px;
}
.fontstyle4 {
  width:73px;
  margin:0px;
}
</style>
<style >
.ant-fullcalendar-content {
  overflow-y: hidden;
  overflow-x: hidden;
}
.ant-fullcalendar-header .ant-radio-group {
    display: none !important;
}
.ant-select-selection {
  width: 6.6rem;
  font-size: 1.2rem;
}
.ant-select-dropdown-menu-item{
  font-size: 1.2rem;
}

#modalQr > .modal-dialog > .modal-content > .modal-body {
  border-color: transparent;
  background-color: transparent !important;
  color: white;
}
#modalQr > .modal-dialog > .modal-content {
  border-color: transparent;
  background-color: transparent !important;
}
</style>
